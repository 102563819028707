import {
    isWishlistEnabled as sourceIsWishlistEnabled,
    WishlistDispatcher as SourceWishlistDispatcher
} from 'SourceStore/Wishlist/Wishlist.dispatcher';

// TODO: implement isWishlistEnabled
export const isWishlistEnabled = sourceIsWishlistEnabled;

/** @namespace Pwa/Store/Wishlist/Dispatcher/WishlistDispatcher */
export class WishlistDispatcher extends SourceWishlistDispatcher {
    updateInitialWishlistData(_dispatch) {

    }
}

export default new WishlistDispatcher();
