import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase/BrowserDatabase';

export function getCartItemById(id) {
    const { items } = BrowserDatabase.getItem(CART_TOTALS);

    const found = items.filter((item) => item.item_id === id);

    if (found.length === 1) {
        return found[0];
    }

    return null;
}
