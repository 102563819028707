import {
    CartQuery as SourceCartQuery
} from 'SourceQuery/Cart.query';
import {
    Field
} from '@tilework/opus';
import ProductListQuery from 'Query/ProductList.query';

/** @namespace Pwa/Query/Cart/Query/CartQuery */
export class CartQuery extends SourceCartQuery {
    // _getCartItemFields() {
    //     return [
    //         'qty',
    //         'sku',
    //         'price',
    //         'item_id',
    //         'row_total',
    //         'row_total_incl_tax',
    //         'tax_amount',
    //         'tax_percent',
    //         'discount_amount',
    //         'discount_percent',
    //         'availability_from',
    //         this._getCustomizableOptionsFields(),
    //         this._getDownloadableLinksField(),
    //         this._getBundleOptionsField(),
    //         this._getProductField()
    //     ];
    // }

    _getCartItemsFields() {
        return [
            'id',
            'uid',
            'quantity',
            'sku',
            this._getCartItemProduct(),
            this._getCartItemPricesField(),
            this._getCartDownloadableProductLinkField(),
            this._getCartBundleProductFragment(),
            this._getCartConfigurableProductFragment(),
            this._getCartVirtualProductFragments(),
            this._getCartSimpleProductFragments(),
        ];
    }

    _getCartItemProduct() {
        return new Field('product')
            .addFieldList(ProductListQuery._getProductInterfaceFields(false));
    }
}

export default new CartQuery();
